import { useTheme } from "@material-ui/core";
import React from "react";
import Xarrow, { xarrowPropsType } from "react-xarrows";

export default function Line(props: xarrowPropsType) {
  const { palette } = useTheme();
  return (
    <Xarrow
      zIndex={-1}
      color={palette.secondary.main}
      showHead={false}
      {...props}
    />
  );
}
