import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, useTheme } from "@material-ui/core";
import React from "react";
import { bounce } from "react-animations";
import Graph from "../Graph";
import HeadlineTyped from "../HeadlineTyped";
import Me from "../Me";
const useStyles = makeStyles((theme) => ({
  "@keyframes bounce": bounce,
  titleContainer: {
    width: "100%",
    display: "flex",
    marginTop: -theme.spacing(5),
    position: "absolute",
    justifyContent: "center",
  },
  graph: {
    paddingTop: theme.spacing(8),
    width: "100%",
    height: `calc(100vh - ${theme.spacing(8)}px)`,
    position: "relative",
    marginTop: -theme.spacing(4),
  },
  downContainer: {
    position: "absolute",
    bottom: 100,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  down: {
    animation: "$bounce 2s infinite",
  },
}));
export interface HomeProps {}
export default function Home(props: HomeProps) {
  const theme = useTheme();
  const classes = useStyles();
  document.body.style.backgroundColor = theme.palette.background.default;

  return (
    <>
      <div className={classes.titleContainer}>
        <HeadlineTyped>
          Timo <br /> Peters
        </HeadlineTyped>
      </div>
      <Graph className={classes.graph} />
      <div className={classes.downContainer}>
        <FontAwesomeIcon
          size="4x"
          color={theme.palette.grey[100]}
          className={classes.down}
          icon={faChevronDown}
        />
      </div>
      <Me />
    </>
  );
}
