import { ThemeProvider } from "@material-ui/core";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Main from "./Main";
import { darkTheme } from "./theme";

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <Main />
      </Router>
    </ThemeProvider>
  );
}

export default App;
