import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import Devlights from "../Devlights";
import HeadlineTyped from "../HeadlineTyped";
import Pbl from "../Pbl";
import Spacer from "../Spacer";
import Teckboard from "../Teckboard";
const useStyles = makeStyles((theme: Theme) => ({
  headline: {
    marginBottom: theme.spacing(4),
  },
}));
function Projects() {
  const classes = useStyles();
  return (
    <>
      <HeadlineTyped className={classes.headline}>My Projects</HeadlineTyped>
      <Teckboard />
      <Spacer />
      <Pbl />
      <Spacer />

      <Devlights />
    </>
  );
}

export default Projects;
