import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItem, ListItemIcon, ListItemText, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "50%",
    borderRadius: theme.shape.borderRadius,
    justifyContent: "flex-start",
    display: "flex",
  },
  icon: {
    color: "white",
  },
  text: {
    color: "white !important",
    flex: "none",
  },
}));
export interface FooterItemProps {
  icon: IconProp | React.ReactChild;
  children: string;
  link?: string;
  local?: boolean;
  newTab?: boolean;
}

export default function FooterItem(props: FooterItemProps) {
  const { icon, children, link, local, newTab = true } = props;
  const classes = useStyles();
  const history = useHistory();
  return (
    <ListItem
      component="a"
      href={!local ? link : undefined}
      target={newTab ? "_blank" : undefined}
      button
      onClick={local ? () => link && history.push(link) : undefined}
      className={classes.root}
    >
      <ListItemIcon>
        {!React.isValidElement(icon) ? (
          <FontAwesomeIcon
            className={classes.icon}
            size="2x"
            icon={icon as IconProp}
          />
        ) : (
          React.cloneElement(icon)
        )}
      </ListItemIcon>
      <ListItemText className={classes.text} primary={children} />
    </ListItem>
  );
}
