import { Button, Grid, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import strip from "../assets/strip_prototype.jpg";
import Headline from "../Headline";
import Image from "../Image";
export interface DevlightsProps {}
const useStyles = makeStyles((theme: Theme) => ({
  gridItem: {
    padding: theme.spacing(4),
  },
  button: {
    margin: theme.spacing(2),
    fontWeight: "bolder",
    color: "black",
  },
}));
export default function Devlights(props: DevlightsProps) {
  const styles = useStyles();
  return (
    <>
      <Headline right id="devlights">
        DevLights
      </Headline>
      <Grid container>
        <Grid className={styles.gridItem} item xs={12} sm={6}>
          <Image about="DevLight" src={strip} />
        </Grid>
        <Grid className={styles.gridItem} item xs={12} sm={6}>
          <Typography
            style={{ width: "100%" }}
            variant="body1"
            color="textPrimary"
          >
            DevLights is a smart LED lightning system controllable from various
            endpoints by API. There is an mobile and desktop app but also game
            integrations like Minecraft or Terraria are possible. It was
            developed as a one term long school project by me and two friends of
            mine. Currently it is open sourced so anyone can write their on
            applications to control DevLights.
          </Typography>
          <Grid container>
            <Button
              className={styles.button}
              href="https://github.com/ProjektDevLights"
              size="large"
              variant="contained"
              color="primary"
            >
              Github
            </Button>
            <Button
              className={styles.button}
              href={`${process.env.PUBLIC_URL}/DevLights.pdf`}
              size="large"
              variant="contained"
              color="secondary"
            >
              Technical Work
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
