import {
  faDiscord,
  faGithub,
  faInstagram,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faBuilding, faUser } from "@fortawesome/free-solid-svg-icons";
import { Grid, Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import devlights from "../assets/devlights_white.svg";
import h4hn from "../assets/h4hn.svg";
import pbl from "../assets/LogoWhite.svg";
import tb from "../assets/tb_plain.svg";
import FooterItem from "../FooterItem";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    clipPath: `polygon(40% ${theme.random({
      from: 0,
      to: 30,
    })}%, 60% ${theme.random({
      from: 0,
      to: 30,
    })}%, 80% ${theme.random({
      from: 0,
      to: 30,
    })}%, 100% ${theme.random({
      from: 0,
      to: 30,
    })}%, 100% 100%, 0 100%, 0 ${theme.random({
      from: 0,
      to: 30,
    })}%, 20% ${theme.random({ from: 0, to: 30 })}%)`,
    height: 400,
    backgroundColor: theme.palette.secondary.main,
    paddingTop: 620 * 0.3,
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: theme.palette.primary.light,
  },
  list: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
}));
function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={4} className={classes.gridItem}>
          <Typography variant="h3" color="initial">
            Projects
          </Typography>
          <FooterItem
            newTab={false}
            icon={<img height={32} alt="teckboard" src={tb} />}
            link="/projects#teckboard"
          >
            TECKboard
          </FooterItem>
          <FooterItem
            newTab={false}
            icon={<img height={32} alt="pbl" src={pbl} />}
            link="/projects#pbl"
          >
            Pick by Light
          </FooterItem>
          <FooterItem
            newTab={false}
            link="/projects#devlights"
            local
            icon={<img height={35} alt="pbl" src={devlights} />}
          >
            DevLights
          </FooterItem>
        </Grid>
        <Grid item xs={4} className={classes.gridItem}>
          <Typography variant="h3" color="initial">
            Others
          </Typography>
          <FooterItem
            icon={<img height={32} alt="h4hn" src={h4hn} />}
            link="https://h4hn.de/"
          >
            Yannik
          </FooterItem>

          <FooterItem icon={faUser} link="https://joshua.slaar.de/">
            Joshua
          </FooterItem>
        </Grid>
        <Grid item xs={4} className={classes.gridItem}>
          <Typography variant="h3" color="initial">
            Me
          </Typography>
          <FooterItem icon={faGithub} link="https://github.com/tpausl">
            TPausL
          </FooterItem>
          <FooterItem
            icon={faLinkedin}
            link="https://www.linkedin.com/in/tpausl/"
          >
            Timo Peters
          </FooterItem>
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;
