import { Theme, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Canvas } from "@react-three/fiber";
import React, { Suspense } from "react";
import Headline from "../Headline";
import Scene from "../Scene";
import ThreeHelperProvider from "../ThreeHelper/ThreeHelperProvider";
const genShadow = (max: number): string => {
  let filter = "";
  for (let i = 1; i <= max; i += 1) {
    filter += `drop-shadow(${i}px -${i}px 0px #c7c116) `;
  }
  return filter + ";";
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(255,255,255,0.05)",
    marginTop: theme.spacing(2),
  },
  box: {
    filter: genShadow(5),
  },
  helperText: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    opacity: 0.5,
    color: "white",
  },
}));
export interface PblProps {}
export default function Pbl(props: PblProps) {
  const classes = useStyles();

  return (
    <>
      <Headline id="pbl">PbL</Headline>
      <div className={classes.root}>
        <Canvas shadows>
          <Suspense fallback={() => <div>loading</div>}>
            <ThreeHelperProvider>
              <Scene />
            </ThreeHelperProvider>
          </Suspense>
        </Canvas>
        <Typography className={classes.helperText}>
          (Click the boxes)
        </Typography>
      </div>
    </>
  );
}
