//@ts-ignore
import dosis from "@fontsource/dosis/files/dosis-all-400-normal.woff";
import { Text } from "@react-three/drei";
import { ThreeEvent } from "@react-three/fiber";
import { max } from "lodash";
import React from "react";
import { Color, Mesh, Vector3 } from "three";
import { useThreeHelper } from "../ThreeHelper";
export interface BoxProps {
  size: Vector3;
  pos: Vector3;
  children?: string;
  clickable?: boolean;
}
export default function Box(props: BoxProps) {
  const { pos, size, children, clickable } = props;
  const threeHelper = useThreeHelper();
  const box = React.useRef<Mesh>();
  const [color, setColor] = React.useState<Color>(
    new Color("rgb(171, 128, 50)")
  );
  const [active, setActive] = React.useState<boolean>(false);
  const handleMissed = () => {
    if (active) {
      setActive(false);
      threeHelper.resetCam();
    }
  };
  const handleClick = (e: ThreeEvent<MouseEvent>) => {
    if (clickable && box.current) {
      if (!active) {
        const pos = new Vector3(
          box.current.position.x,
          box.current.position.y,
          box.current.position.z
        );
        threeHelper.setCam(
          new Vector3()
            .copy(pos)
            .add(
              new Vector3(
                0,
                0,
                (max([box.current.scale.x, box.current.scale.y]) as number) *
                  1.5
              )
            ),
          pos
        );
        setActive(true);
      }
      handleMissed();
    }
  };

  return (
    <>
      <mesh
        visible
        onPointerMissed={handleMissed}
        onPointerEnter={() => {
          if (clickable) {
            document.body.style.cursor = "pointer";
            setColor(new Color("rgb(207, 163, 85)"));
            console.log();
          }
        }}
        onPointerLeave={() => {
          if (clickable) {
            document.body.style.cursor = "default";
            setColor(new Color("rgb(171, 128, 50)"));
          }
        }}
        onClick={handleClick}
        position={threeHelper.scalePos(pos, size)}
        scale={size}
        ref={box}
      >
        <boxGeometry />
        <meshPhysicalMaterial color={"#" + color.getHexString()} />
      </mesh>
      {children && (
        <>
          <Text
            anchorX="left"
            anchorY="top"
            color="black"
            font={dosis}
            fontSize={0.8}
            maxWidth={size.x - 2}
            position={threeHelper.textPos(pos, size).add(new Vector3(1, -1, 0))}
          >
            {children}
          </Text>
        </>
      )}
    </>
  );
}
