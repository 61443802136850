import {
  makeStyles,
  Theme,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import Typist from "react-typist";
const useStyles = makeStyles((theme: Theme) => ({
  headline: {
    zIndex: 10,
    cursor: "default",
    fontFamily: "bungee",
    fontWeight: "normal",
  },
  typist: {
    zIndex: 10,
    textShadow: `5px 5px ${theme.palette.secondary.main}, 4px 4px ${theme.palette.secondary.main}, 3px 3px ${theme.palette.secondary.main}`,
    "&:hover": {
      textShadow: `5px 5px ${theme.palette.primary.main}, 4px 4px ${theme.palette.primary.main}, 3px 3px ${theme.palette.primary.main}`,
    },
  },
}));
export interface HeadlineTypedProps extends TypographyProps {
  children: React.ReactNode;
}
export default function HeadlineTyped(props: HeadlineTypedProps) {
  const { className, ...rest } = props;
  const classes = useStyles();
  return (
    <Typist className={classes.typist} cursor={{ show: false }}>
      <Typography
        className={clsx(classes.headline, props.className)}
        align="center"
        color="textPrimary"
        variant="h1"
        {...rest}
      >
        {props.children}
      </Typography>
    </Typist>
  );
}
