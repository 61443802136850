import React from "react";
import { useLocation } from "react-router";

export interface ScrollHandlerProps {}
export default function ScrollHandler(props: ScrollHandlerProps) {
  const { pathname, hash } = useLocation();
  React.useEffect(() => {
    if (hash) {
      document.getElementById(hash.substr(1))?.scrollIntoView();
    }
  }, [pathname, hash]);
  return null;
}
