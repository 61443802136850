import { makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import Tilt from "react-parallax-tilt";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundSize: "contain",
    position: "relative",
  },
  image: {
    overflow: "hidden",
    borderRadius: "5%",
    boxShadow: theme.shadows[10],
  },
  title: {
    color: (props: ImageProps) =>
      props.darkFont ? "rgba(0,0,0,.4)" : "rgba(255,255,255,.4)",
    fontWeight: "bold",
    position: "absolute",
    right: theme.spacing(4),
    bottom: theme.spacing(4),
  },
  tilt: {
    height: "fit-content",
    width: "fit-content",
    rotate: theme.random() + "deg",
  },
}));
export interface ImageProps extends React.HTMLAttributes<HTMLDivElement> {
  src: string;
  size?: number;
  children?: string;
  darkFont?: boolean;
}
export default function Image(props: ImageProps) {
  const { children, size, src, className, ...rest } = props;
  const classes = useStyles(props);
  return (
    <Tilt
      perspective={5000}
      className={clsx(classes.tilt, className)}
      {...rest}
    >
      {children && (
        <Typography className={classes.title} variant="h2" color="initial">
          {children}
        </Typography>
      )}
      <img
        alt={children ?? "image"}
        className={classes.image}
        width={size ?? "100%"}
        src={src}
      />
    </Tilt>
  );
}
