import React from "react";
import {
  ThreeHelperContext,
  ThreeHelperContextType,
} from "./ThreeHelperProvider";

function useThreeHelper() {
  return React.useContext<ThreeHelperContextType>(ThreeHelperContext);
}

export default useThreeHelper;
