import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import tbapp from "../assets/tb_app.jpg";
import tb from "../assets/tb_plain.svg";
import tbschool from "../assets/tb_school.jpg";
import Content from "../Content";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: (props: { width: number }) => props.width,
    color: "white",
  },
  header: {
    height: 60,
    backgroundColor: "#192C4C",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  img: {
    marginLeft: 20,
  },
  contentContainer: {
    background: "rgba(255,255,255,0.1)",
    height: (props: { width: number }) =>
      `calc((${props.width}px * 9 / 16) - 60px)`,
    position: "relative",
    boxShadow: theme.shadows[3],
  },
  headerText: {
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
  text: {
    fontFamily: "Open Sans",
    fontSize: "1.3em",
  },
  helperText: {
    position: "absolute",
    right: theme.spacing(1),
    bottom: theme.spacing(1),
    opacity: 0.5,
  },
}));
function Teckboard() {
  const [width, setWidth] = React.useState<number>(document.body.clientWidth);
  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(document.body.clientWidth);
    });
    setWidth(document.body.clientWidth);
  }, []);
  const classes = useStyles({ width });
  const [zIndex, setZIndex] = React.useState<number>(1);
  return (
    <div id="teckboard" className={classes.root}>
      <div className={classes.header}>
        <img
          className={classes.img}
          height={40}
          alt="teckboard-logo"
          src={tb}
        />
        <div>
          <Typography className={classes.headerText} variant="h4">
            TECKboard
          </Typography>
        </div>
        <div></div>
      </div>
      <div className={classes.contentContainer}>
        <Content
          zIndex={zIndex}
          setzIndex={setZIndex}
          height="37.5%"
          top="10%"
          left="65%"
        >
          <img
            draggable={false}
            height="100%"
            alt="teckboard-school"
            src={tbschool}
          />
        </Content>
        <Content
          zIndex={zIndex}
          setzIndex={setZIndex}
          height="60%"
          top="30%"
          left="5%"
        >
          <img
            draggable={false}
            height="100%"
            alt="teckboard-app"
            src={tbapp}
          />
        </Content>
        <Content
          zIndex={zIndex}
          setzIndex={setZIndex}
          text
          width="25%"
          height="36%"
          top="5%"
          left="30%"
        >
          <p style={{ fontWeight: "bold", display: "inline" }}>TECKboard</p> is
          a real time digital information system with flexability in mind.
          <br /> It gives the ability to create individual boards, create
          contents on them and invite users, so that everyone just gets the
          information they need and are allowed to see.
          <br />
          The idea came up when our school wanted to replace our old analog
          blackboard with a digital version but none suited their needs.
        </Content>

        <Content
          zIndex={zIndex}
          setzIndex={setZIndex}
          text
          width="30%"
          height="25%"
          top="60%"
          left="45%"
        >
          My colleauge{" "}
          <a target="_blank" rel="noreferrer" href="https://h4hn.de">
            Yannik Hahn
          </a>{" "}
          and I have been developing it over the course of a year and put all
          our effort into it in order to create an amazing project that fitted
          our high standards. I also helped my colleauge{" "}
          <a target="_blank" rel="noreferrer" href="https://joshua.slaar.de">
            Joshua Slaar
          </a>
          &nbsp;creating a mobile app in which you can view the contents on your
          smartphone.
        </Content>

        <Typography className={classes.helperText}>
          (They're moveable)
        </Typography>
      </div>
    </div>
  );
}

export default Teckboard;
