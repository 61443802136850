import { useLoader } from "@react-three/fiber";
import React from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export default function Shelf() {
  const gltf = useLoader(GLTFLoader, process.env.PUBLIC_URL + "/shelf5.gltf");
  return (
    <>
      <primitive
        position={[-42, 40, 0]}
        object={gltf.scene}
        material={() => <meshPhysicalMaterial wireframe metalness={10} />}
      />
    </>
  );
}
