import { useFrame, useThree } from "@react-three/fiber";
import tween from "@tweenjs/tween.js";
import React from "react";
import { Vector3 } from "three";

export interface ThreeHelperProps {
  children: React.ReactNode;
}
export interface ThreeHelperContextType {
  resetCam: () => void;
  setCam: (pos: Vector3, lookAt?: Vector3) => void;
  pos: (x: number, y: number, z: number) => Vector3;
  scalePos: (pos: Vector3, scale: Vector3) => Vector3;
  textPos: (pos: Vector3, scale: Vector3) => Vector3;
}
export const ThreeHelperContext = React.createContext<ThreeHelperContextType>({
  resetCam: () => undefined,
  setCam: () => undefined,
  pos: () => new Vector3(),
  scalePos: () => new Vector3(),
  textPos: () => new Vector3(),
});
export default function ThreeHelperProvider(props: ThreeHelperProps) {
  const three = useThree();
  const curLookAt = new Vector3(0, 80, 0);
  useFrame(() => {
    tween.update();
  });

  const tweenVector = (
    from: Vector3,
    to: Vector3,
    update?: (cur: Vector3) => void
  ) => {
    new tween.Tween(from)
      .to(to, 300)
      .easing(tween.Easing.Cubic.InOut)
      .onUpdate((cur) => {
        update && update(cur);
      })
      .start();
  };
  const resetCam = () => {
    setCam(new Vector3(0, 90, 50), new Vector3(0, 80, 0));
  };
  const setCam = (pos: Vector3, lookAt?: Vector3) => {
    tweenVector(three.camera.position, pos);
    if (lookAt) {
      tweenVector(curLookAt, lookAt, (cur) => three.camera.lookAt(cur));
      curLookAt.copy(lookAt);
    }
  };
  const pos = (x: number, y: number, z: number): Vector3 => {
    return new Vector3(x - 42, y + 40, z);
  };
  const correct = (v: Vector3): Vector3 => {
    return copy(v).add(new Vector3(-42, 40, 0));
  };
  const copy = (v: Vector3): Vector3 => {
    return new Vector3().copy(v);
  };

  const scalePos = (pos: Vector3, scale: Vector3): Vector3 => {
    return correct(pos).add(copy(scale).divide(new Vector3(2, 2, -2)));
  };
  const textPos = (pos: Vector3, scale: Vector3): Vector3 => {
    return scalePos(pos, scale).add(copy(scale).divide(new Vector3(-2, 2, 2)));
  };
  return (
    <ThreeHelperContext.Provider
      value={{
        resetCam,
        setCam,
        pos,
        scalePos,
        textPos,
      }}
    >
      {props.children}
    </ThreeHelperContext.Provider>
  );
}
