import { faHome, faProjectDiagram } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { slideInDown } from "react-animations";
import home from "../assets/home.jpg";
import work from "../assets/teckdigital_team.jpeg";
import Headline from "../Headline";
import Image from "../Image";
const useStyles = makeStyles((theme) => ({
  "@keyframes slide": slideInDown,
  root: {
    marginTop: theme.spacing(4),
  },
  gridContainer: {
    marginTop: theme.spacing(10),
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    width: "fit-content",
  },
  textGrid: {
    position: "relative",
    alignItems: "center",
    display: "flex",
  },
  homeIcon: {
    position: "absolute",
    top: theme.spacing(2),
    right: "30%",
    rotate: theme.random({ from: -15, to: 15 }) + "deg",
    color: theme.palette.secondary.light,
    opacity: 0.5,
    zIndex: -1,
    animation: "$slide 5s infinite alternate",
  },
  gridContainer2: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
}));
function Me() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Headline id="about" left>
        About Me
      </Headline>
      <Grid className={classes.gridContainer} container>
        <Grid style={{ padding: "2.5%" }} item xs={10} md={6}>
          <Image src={home}>@Home</Image>
        </Grid>
        <Grid className={classes.textGrid} item xs={12} md={6}>
          <Typography
            style={{ width: "100%" }}
            variant="body1"
            color="textPrimary"
          >
            Spending time with my friends, might it be for fun or working
            together at a school or spare time project, is an important part of
            my life. Besides that I spend a lot of time with my family. I draw
            new ideas and strength from the conversation with others. In my free
            time I play tennis and ride my bike a lot. The balance between
            mental and physical exercise in addition to advice of others help me
            achieve best results.
          </Typography>
          <FontAwesomeIcon
            size="10x"
            className={classes.homeIcon}
            icon={faHome}
          />
        </Grid>
      </Grid>
      <Headline id="expertise" right>
        Expertise
      </Headline>

      <Grid
        className={clsx(classes.gridContainer, classes.gridContainer2)}
        container
      >
        <Grid
          style={{ padding: "2.5%" }}
          className={classes.textGrid}
          item
          xs={12}
          md={6}
        >
          <Typography
            style={{ width: "100%" }}
            variant="body1"
            color="textPrimary"
          >
            Most of my programming knowledge I gained by self-learning following
            the learning by doing principle. I was part of the school-company
            TECKdigital at our school and by participating in it I gained
            insight in how a company works and how to collaborate effectivly. I
            am always keen on learning new things and like to experiment with
            modern technologies. That way I get a broad insight and can choose
            the most interesting to deal with in-depth and get an expert in it.
          </Typography>
          <FontAwesomeIcon
            size="10x"
            className={classes.homeIcon}
            icon={faProjectDiagram}
          />
        </Grid>
        <Grid item xs={10} md={6}>
          <Image src={work}>@Work</Image>
        </Grid>
      </Grid>
    </div>
  );
}

export default Me;
