import React from "react";
import { Redirect, Route, Switch } from "react-router";
import Error from "../Error";
import Footer from "../Footer";
import Header from "../Header";
import Home from "../Home";
import Projects from "../Projects";
import ScrollHandler from "../ScrollHandler";
export interface MainProps {}
export default function Main(props: MainProps) {
  return (
    <>
      <ScrollHandler />
      <Header />
      <Switch>
        <Redirect exact from="/" to="/home" />
        <Route exact path="/home">
          <Home />
        </Route>
        <Route exact path="/projects">
          <Projects />
        </Route>
        <Route path="*">
          <Error />
        </Route>
      </Switch>
      <Footer />
    </>
  );
}
