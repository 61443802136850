import { makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { Rnd } from "react-rnd";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: "absolute",
    height: "100%",
    borderRadius: theme.shape.borderRadius * 3,
    overflow: "hidden",
    boxShadow: theme.shadows[2],
    display: "block",
    "& a": {
      color: theme.palette.primary.light,
    },
  },
  header: {
    backgroundColor: "#192C4C",
    height: 25,
    width: "100%",
  },
  content: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: "#F9A041",
    color: "black",
    width: "100%",
    height: "calc(100% - 30px)",
    overflowY: "auto",
  },
  textContainer: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
  text: {
    fontFamily: "Open Sans",
    fontSize: "1.25em",
  },
}));
export interface ContentProps {
  left: number | string;
  top: number | string;
  width?: number | string;
  height: number | string;
  children: string | React.ReactNode;
  text?: boolean;
  zIndex: number;
  noMove?: boolean;
  setzIndex: (index: number) => void;
}
export default function Content(props: ContentProps) {
  const {
    children,
    left,
    top,
    width,
    height,
    text,
    zIndex,
    setzIndex,
    noMove,
  } = props;
  const classes = useStyles(props);
  const [ownzIndex, setOwnzIndex] = React.useState<number>(zIndex);

  const [pos, setPos] = React.useState({ x: 0, y: 0 });
  React.useEffect(() => {
    setPos({
      x:
        typeof left === "string"
          ? (document.body.clientWidth * parseFloat(left)) / 100
          : left,
      y:
        typeof top === "string"
          ? (((document.body.clientWidth * 9) / 16 - 60) * parseFloat(top)) /
            100
          : top,
    });
  }, [document.body.clientWidth]);
  return (
    <Rnd
      bounds="parent"
      /* default={{
        x: pos.x,
        y: pos.y,
        width: width ?? "fit-content",
        height,
      }} */
      size={{
        height,
        width: width ?? "fit-content",
      }}
      position={{
        x: pos.x,
        y: pos.y,
      }}
      onDragStart={() => {
        setOwnzIndex(zIndex + 1);
      }}
      onDrag={(e) => {
        e.stopPropagation();
      }}
      onDragStop={(_e, d) => {
        setzIndex(ownzIndex);
        setPos({ x: d.x, y: d.y });
      }}
      enableResizing={{
        bottom: false,
        bottomLeft: false,
        bottomRight: false,
        left: false,
        right: false,
        top: false,
        topLeft: false,
        topRight: false,
      }}
      style={{ zIndex: ownzIndex }}
      disableDragging={noMove}
    >
      <div className={classes.root}>
        {text ? (
          <>
            <div className={classes.header}></div>
            <div className={classes.content}>
              <div className={classes.textContainer}>
                <Typography
                  component="div"
                  variant="body1"
                  className={classes.text}
                >
                  {children}
                </Typography>
              </div>
            </div>
          </>
        ) : (
          <>{children}</>
        )}
      </div>
    </Rnd>
  );
}
