import { PerspectiveCamera } from "@react-three/drei";
import React from "react";
import { Vector3 } from "three";
import Box from "../Box";
import Shelf from "../Shelf";

export interface SceneProps {}

export default function Scene(props: SceneProps) {
  const cam = React.useRef();
  React.useEffect(() => {
    if (cam.current) {
      //@ts-ignore
      cam.current.lookAt(new Vector3(0, 80, 0));
    }
  }, [cam.current]);
  return (
    <>
      <ambientLight intensity={0.1} />
      <directionalLight position={new Vector3(-40, 80, 100)} />
      <directionalLight position={new Vector3(40, 80, 100)} />
      <directionalLight intensity={0.1} position={new Vector3(-40, 40, -100)} />
      <directionalLight intensity={0.1} position={new Vector3(40, 40, -100)} />

      <PerspectiveCamera
        ref={cam}
        fov={75}
        far={1000}
        position={new Vector3(0, 90, 50)}
        makeDefault
      />
      <Shelf />
      <Box
        clickable
        pos={new Vector3(5, 19.5, -2)}
        size={new Vector3(16, 12, 12)}
      >
        It consists of two major parts. First is the software. It stores where
        all products are and calculates which LEDs should be lit once a product
        is requested. Second is the Hardware which actually turns the LEDs on.
      </Box>
      <Box pos={new Vector3(5, 19.5, -15)} size={new Vector3(16, 12, 12)} />

      <Box pos={new Vector3(5, 32, -2)} size={new Vector3(16, 12, 12)} />
      <Box pos={new Vector3(5, 32, -15)} size={new Vector3(16, 12, 12)} />

      <Box pos={new Vector3(23.5, 19.5, -2)} size={new Vector3(12, 6, 24)} />
      <Box pos={new Vector3(23.5, 26, -2)} size={new Vector3(12, 6, 24)} />
      <Box pos={new Vector3(23.5, 32.5, -2)} size={new Vector3(12, 6, 24)} />
      <Box pos={new Vector3(23.5, 39, -2)} size={new Vector3(12, 6, 24)} />

      <Box pos={new Vector3(38, 19.5, -2)} size={new Vector3(16, 6, 24)} />
      <Box pos={new Vector3(38, 26, -2)} size={new Vector3(16, 6, 24)} />
      <Box pos={new Vector3(38, 32.5, -2)} size={new Vector3(16, 6, 24)} />
      <Box pos={new Vector3(38, 39, -2)} size={new Vector3(16, 6, 24)} />

      <Box pos={new Vector3(56, 19.5, -2)} size={new Vector3(12, 8, 24)} />
      <Box pos={new Vector3(56, 28, -2)} size={new Vector3(12, 8, 24)}></Box>
      <Box pos={new Vector3(56, 36.5, -2)} size={new Vector3(12, 8, 24)} />

      <Box pos={new Vector3(70, 19.5, -2)} size={new Vector3(8, 6, 24)} />
      <Box pos={new Vector3(70, 26, -2)} size={new Vector3(8, 6, 24)} />
      <Box pos={new Vector3(70, 32.5, -2)} size={new Vector3(8, 6, 24)} />
      <Box pos={new Vector3(70, 39, -2)} size={new Vector3(8, 6, 24)} />

      <Box
        clickable
        pos={new Vector3(5, 56, -2)}
        size={new Vector3(12, 12, 12)}
      >
        PbL short for PickByLight is a smart storage solution I have been
        developing with TECKdigital. Once configured you can pick a product you
        want to commisionate and all places in which parts you need are stored
        will light up.
      </Box>

      <Box pos={new Vector3(19.5, 56, -2)} size={new Vector3(20, 6, 24)} />
      <Box pos={new Vector3(19.5, 62.5, -2)} size={new Vector3(20, 6, 24)} />

      <Box
        clickable
        pos={new Vector3(42, 56, -2)}
        size={new Vector3(12, 9, 24)}
      >
        The system was an order development from a company producing shower
        cubicles and we have been installing it in one of their storage spaces.
      </Box>

      <Box pos={new Vector3(56, 56, -2)} size={new Vector3(12, 8, 24)} />
      <Box pos={new Vector3(56, 64.5, -2)} size={new Vector3(12, 8, 24)} />

      <Box pos={new Vector3(70, 56, -2)} size={new Vector3(8, 6, 24)} />
      <Box pos={new Vector3(70, 62.5, -2)} size={new Vector3(8, 6, 24)} />
    </>
  );
}
