import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { JSXElementConstructor, ReactElement } from "react";
import Tilt from "react-parallax-tilt";

export interface CircleProps extends React.HTMLAttributes<HTMLElement> {
  radius: number;
  icon?: IconProp | React.ReactNode;
  className?: string;
  color?: "primary" | "secondary" | string;
  x: number;
  y: number;
  tilt?: boolean;
  onClick?: () => void;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
}
const useStyle = makeStyles((theme) => ({
  root: {
    height: (props: CircleProps) => props.radius * 32,
    width: (props: CircleProps) => props.radius * 32,
    borderRadius: "50%",
    backgroundColor: (props: CircleProps) =>
      props.color === "secondary"
        ? theme.palette.secondary.main
        : props.color === "primary"
        ? theme.palette.primary.main
        : props.color ?? theme.palette.background.paper,
    left: (props: CircleProps) =>
      `calc(${props.x * 5}% - ${props.radius * 16}px)`,
    top: (props: CircleProps) =>
      `calc(${props.y * 5}% - ${props.radius * 16}px)`,
    position: "absolute",
    overflow: "hidden",
    filter: "drop-shadow(0px 2px 16px rgba(0,0,0,.4))",
    cursor: (props: CircleProps) => (props.onClick ? "pointer" : undefined),
  },
  inner: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
function Circle(props: CircleProps) {
  const { className, children, id, tilt, onClick, ...rest } = props;
  const classes = useStyle(props);
  return (
    <Tilt
      scale={tilt || onClick ? 1.2 : undefined}
      tiltEnable={tilt === true ?? false}
      className={clsx(classes.root, props.className)}
      {...rest}
    >
      <div id={id} className={classes.inner}>
        {React.cloneElement(children, { ...children.props, onClick })}
      </div>
    </Tilt>
  );
}

export default Circle;
