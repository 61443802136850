import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Bounce } from "react-awesome-reveal";

const rand = () => Math.random() * 15 + 5;

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: (props: HeadlineProps) =>
      props.left
        ? theme.spacing(4)
        : props.right
        ? `calc(60% - ${theme.spacing(4)}px)`
        : undefined,
    width: "42%",
    height: 140,
    filter: "drop-shadow(5px 5px 8px rgba(0, 0, 0, .4))",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      marginLeft: `5% !important`,
    },
  },
  clip: {
    background: `linear-gradient(85deg, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
    display: "flex",
    height: "100%",
    width: "100%",
    clipPath: `polygon(0 ${rand()}%, 100% 0, ${
      100 - rand()
    }% 100%, ${rand()}% 100%);`,
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    //color: theme.palette.secondary.main,
    position: "absolute",
    filter: `drop-shadow(3px 3px 2px rgba(0,0,0,.4))`,
    backgroundImage: `linear-gradient(85deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.light})`,
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    color: theme.palette.secondary.main,
    fontWeight: 900,
    [theme.breakpoints.down("sm")]: {
      fontSize: "4rem",
    },
  },
}));
export interface HeadlineProps {
  children: React.ReactNode;
  left?: boolean;
  right?: boolean;
  center?: boolean;
  id?: string;
}
export default function Headline(props: HeadlineProps) {
  const { id, children } = props;
  const classes = useStyles(props);
  return (
    <Bounce fraction={1} triggerOnce>
      <div id={id} className={classes.root}>
        <div className={classes.clip}>
          <Typography className={classes.text} color="textPrimary" variant="h1">
            {children}
          </Typography>
        </div>
      </div>
    </Bounce>
  );
}
