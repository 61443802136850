import { Grid, IconButton, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import logo from "../assets/logo.svg";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    color: "white",
    padding: theme.spacing(2),
  },
  logoButton: {
    margin: theme.spacing(0.5),
  },
}));
export interface HeaderProps {}
export default function Header(props: HeaderProps) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid justifyContent="space-between" container className={classes.root}>
      <Grid item>
        <IconButton onClick={() => history.push("/")}>
          <img
            className={classes.logoButton}
            height={50}
            alt="logo"
            src={logo}
          />
        </IconButton>
      </Grid>
    </Grid>
  );
}
