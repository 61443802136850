import "@fontsource/bungee";
import "@fontsource/dosis";
import "@fontsource/open-sans";
import { createTheme } from "@material-ui/core";

interface Range {
  from: number;
  to: number;
}
declare module "@material-ui/core/styles/createTheme" {
  interface Theme {
    random: (range?: Range) => number;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    random?: (range?: Range) => number;
  }
}
export const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: { main: "#07ebc8" },
    secondary: { main: "#FB6340" },
  },
  typography: {
    fontFamily: ["dosis", "open-sans", "bungee"].join(","),
    body1: {
      fontSize: "1.5rem",
      //color: "#202b2a",
    },
  },
  random: (range?: Range) => {
    if (!range) return Math.random() * 10 - 5;
    const min = Math.ceil(range.from);
    const max = Math.floor(range.to);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },
});
